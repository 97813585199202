import request from '@/utils/request'

const newsApi = {
  // get news info
  news: '/api/v1/rest/news',
  findByKeyword: '/api/v1/rest/news/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/news/search/findByHouseId'
}

/**
 * list
 * @param parameter { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function news (parameter) {
  return request({
    url: newsApi.news + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function addNews (parameter) {
  return request({
    url: newsApi.news,
    method: 'post',
    data: parameter
  })
}

/**
 * edit
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}`,
    method: 'patch',
    data: parameter
  })
}

/**
 * del
 * @param parameter { id: '' }
 * @returns {*}
 */
export function delNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}`,
    method: 'delete'
  })
}

/**
 * put
 * @param parameter { id: '' }
 * @returns {*}
 */
export function putNewsMedia (newsId, data) {
  return request({
    url: newsApi.news + '/' + newsId + '/media',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * del
 * @param parameter { id: '' }
 * @returns {*}
 */
export function getNewsMedia (newsId) {
  return request({
    url: newsApi.news + '/' + newsId + '/media',
    method: 'get'
  })
}

/**
 * search
 * @param parameter { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findNews (parameter) {
  return request({
    url: newsApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * search
 * @param parameter { id: '' }
 * @returns {*}
 */
export function findByIdNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}`,
    method: 'get'
  })
}

/**
 * news
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function inNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { name: '', content: '' }
 * @returns {*}
 */
export function addInNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}/${parameter.name}`,
    data: parameter
  })
}

/**
 * patch
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function editInNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}/${parameter.name}`,
    method: 'patch'
  })
}

/**
 * findByHouses_Id
 * @param parameter { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdNews (parameter) {
  return request({
    url: newsApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}
