<template>
  <a-modal
    title="楼盘动态"
    :width="800"
    :visible="visible"
    :confirmLoading="loading"
    @ok="
      () => {
        $emit('ok')
      }
    "
    @cancel="
      () => {
        $emit('cancel')
      }
    "
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="分类标签">
          <a-input
            v-decorator="[
              'category',
              {
                rules: [
                  { required: true, message: '请输入分类标签！' },
                  { max: 20, message: '长度不能大于20' }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="内容">
          <a-textarea
            :auto-size="{ minRows: 6, maxRows: 9 }"
            v-decorator="[
              'content',
              {
                rules: [
                  { required: true, message: '请输入内容！' },
                  { max: areaLimitLength, message: areaLimitText }
                ]
              }
            ]"
          ></a-textarea>
        </a-form-item>
        <a-form-item
          label="上传图片"
          extra="支持扩展名：.jpg, .jpeg, .png, .mp4"
          :labelCol="{ lg: { span: 7 }, sm: { span: 15 } }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 17 } }"
        >
          <uploadOss :media="media" @emitFiles="getFileList"></uploadOss>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { UploadOss } from '@/components'

// 表单字段
const fields = ['category', 'content']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    media: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UploadOss
  },
  data() {
    return {
      form: this.$form.createForm(this)
    }
  },
  methods: {
    getFileList(data) {
      // this.media = data
      this.$emit('emitMedia', data)
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
