var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"楼盘动态","width":800,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":function () {
      _vm.$emit('ok')
    },"cancel":function () {
      _vm.$emit('cancel')
    }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-form-item',{attrs:{"label":"分类标签"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'category',
            {
              rules: [
                { required: true, message: '请输入分类标签！' },
                { max: 20, message: '长度不能大于20' }
              ]
            }
          ]),expression:"[\n            'category',\n            {\n              rules: [\n                { required: true, message: '请输入分类标签！' },\n                { max: 20, message: '长度不能大于20' }\n              ]\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'content',
            {
              rules: [
                { required: true, message: '请输入内容！' },
                { max: _vm.areaLimitLength, message: _vm.areaLimitText }
              ]
            }
          ]),expression:"[\n            'content',\n            {\n              rules: [\n                { required: true, message: '请输入内容！' },\n                { max: areaLimitLength, message: areaLimitText }\n              ]\n            }\n          ]"}],attrs:{"auto-size":{ minRows: 6, maxRows: 9 }}})],1),_c('a-form-item',{attrs:{"label":"上传图片","extra":"支持扩展名：.jpg, .jpeg, .png, .mp4","labelCol":{ lg: { span: 7 }, sm: { span: 15 } },"wrapperCol":{ lg: { span: 12 }, sm: { span: 17 } }}},[_c('uploadOss',{attrs:{"media":_vm.media},on:{"emitFiles":_vm.getFileList}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }